import Box from '@mui/material/Box';
import type { NextPage } from 'next';
import { GetServerSidePropsContext } from 'next';
import Head from 'next/head';
import { AuthGuard } from 'src/components/auth/auth-guard';
import { DashboardLayout } from 'src/components/dashboard/dashboard-layout';
import { LoansFilters } from 'src/components/loans/loans-filters';
import { LoansKanbanMapper } from 'src/components/loans-kanban';
import { SplashScreen } from 'src/components/splash-screen';
import { LoanRoleGuard } from 'src/components/user/loan-role-guard';
import { LoanStageCategory } from 'src/constants/loan';
import { PageLabel, Route } from 'src/constants/ui';
import { Permission } from 'src/constants/user';
import { CustomizedTableProvider } from 'src/contexts/customized-table-context';
import { KanbanLoansProvider } from 'src/contexts/loans-kanban-context';
import { useNavbarTitle } from 'src/hooks/use-navbar-title';
import { useUser } from 'src/hooks/use-user';
import { SSRRepository } from 'src/ssr/ssr-repository';
import { roleCan } from 'src/utils/user/role-can';


const Home: NextPage = () => {
  useNavbarTitle(PageLabel.Origination);
  const { userRole, isLender, company, user } = useUser();

  if (!user) {
    return <SplashScreen />;
  }

  return (<>
    <Head>
      <title>{PageLabel.Origination}</title>
    </Head>
    <KanbanLoansProvider
      phaseCategory={LoanStageCategory.ORIGINATION}
      companyId={company?.id}>
      <CustomizedTableProvider>
        <Box
          className="bg-black-10"
          component="main"
          sx={{
            flexGrow: 1,
            '& .loans-kanban-container': {
              height: `calc(100vh - ${isLender && roleCan(userRole, Permission.CrudLoan)
                ? '140'
                : '56'
                }px)`,
            },
          }}
        >
          <LoanRoleGuard permission={Permission.FilterLoans}>
            <LoansFilters lenderId={company?.id} />
          </LoanRoleGuard>
          <LoansKanbanMapper />
        </Box>
      </CustomizedTableProvider>
    </KanbanLoansProvider>
  </>);
};

Home.getLayout = (page) => (
  <AuthGuard>
    <DashboardLayout>
      {page}
    </DashboardLayout>
  </AuthGuard>
);

export async function getServerSideProps(context: GetServerSidePropsContext) {
  context.res.setHeader('Cache-Control', 'no-store');
  // if we don't have a cookie return props
  const options = {
    headers: {
      'Cookie': context.req.headers.cookie,
    }
  }
  // check if user is authenticated
  // if mot just return props
  const isAuthenticated = await SSRRepository.isUserAuthenticated(options);
  if (!isAuthenticated) {
    return {
      props: {}
    }
  }
  try {

    // get logged in user
    const user = await SSRRepository.getLoggedUser(options);

    // if logged user is admin redirect to admin page
    if (user.viewType === "ADMIN") {
      return {
        redirect: {
          destination: Route.ADMIN_PAGE,
          permanent: false
        }
      }
    }
    // if user is not borrower return props
    if (user.viewType === "LENDER") {
      return {
        props: {},
      };
    }
    // if referrer is not null
    // and referrer is not the same domain as the current domain
    // and user is a borrower, redirect to portal
    // if (isBorrowerRedirectNeeded(referrer, currentDomain)) {
    const lenders = await SSRRepository.getBorrowerLenders(options)
    // if we only have one lender 
    // redirect to borrower portal for that lender
    if (lenders.length === 1) {
      return {
        redirect: {
          destination: `/portal/${lenders[0].id}`,
          permanent: false,
        },
      };
    }

    return {
      redirect: {
        destination: '/portal',
        permanent: false,
      },
    };


  } catch (error) {
    console.error(error);
  }
  return {
    props: {},
  };
}

export default Home;
